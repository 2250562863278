<template>
    <div class="membership-img-container p-relative">
        <div class="img-wrapper">
            <img
                v-if="selectedMembership === 'mf_3month' && me.gender == 0"
                :src="require('@/assets/images/membership/marry-fit-male-2.png')"
                alt=""
            />
            <img
                v-else-if="selectedMembership === 'mf_3month' && me.gender == 1"
                :src="require('@/assets/images/membership/marry-fit-female-2.png')"
                alt=""
            />
            <img v-else :src="require('@/assets/images/membership/marry-fit-plus-2.png')" alt="" />
        </div>
        <div v-if="selectedMembership === 'mf_3month' && me.gender === 0" class="carousel-container">
            <swiper ref="mySwiper" :options="swiperOptions" class="swiper-container">
                <swiper-slide v-for="photo in photos" :key="photo.id">
                    <img style="width: 105%" :src="photo.url" alt="" />
                </swiper-slide>

                <div slot="button-next" class="swiper-button-next">
                    <img
                        :src="require(`@/assets/images/membership/marry-fit-carousel/carousel-back.png`)"
                        class="btn-img"
                    />
                </div>
            </swiper>
        </div>
    </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
    name: 'MembershipDetail2',
    props: ['selectedMembership'],
    components: {
        swiper,
        swiperSlide,
    },
    data: () => ({
        photos: [],
        swiperOptions: {
            initialSlide: 0,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
                clickable: true,
            },
            grabCursor: true,
            slidesPerView: 1.2,
            spaceBetween: 10,
        },
    }),
    computed: {
        photoList() {
            const photoList = [
                {
                    id: 1,
                    url: require('@/assets/images/membership/marry-fit-carousel/male-1.png'),
                },
                {
                    id: 2,
                    url: require('@/assets/images/membership/marry-fit-carousel/male-2.png'),
                },
                {
                    id: 3,
                    url: require('@/assets/images/membership/marry-fit-carousel/male-3.png'),
                },
                {
                    id: 4,
                    url: '',
                },
            ]

            return photoList
        },
        me() {
            return this.$store.getters.me
        },
        products() {
            return this.$store.getters.products
        },
    },
    mounted() {
        this.photos = this.photoList
    },
}
</script>

<style scoped lang="scss">
.carousel-container {
    width: 100%;
    max-width: 515px;
    position: absolute;
    bottom: 0px;
    padding-left: 15px;
    padding-bottom: 20px;

    .swiper-slide {
        padding-bottom: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        .page-image {
            padding-bottom: 10px;
            width: 90%;
            margin: 0 auto;
        }
    }
    .swiper-button-next {
        $size: 10%;
        width: $size;
        height: $size;
        background-image: none;
        position: absolute;
        top: 80%;
        right: 9%;

        .btn-img {
            width: 100%;
        }

        &::after {
            content: none;
        }
    }
}
</style>
